@import 'components/view/scss/global.scss';

.hoverNav {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: $scale11;
  min-width: $scale10;
  z-index: 11;

  &:after {

    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 10px;

  }

  nav {

    position: absolute;
    width: 100%;
    padding: 0;
    text-align: left;
    border-radius: $radius;
    border: 1px $c-dark;
    border-style: dotted;
    box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.50);
    background: rgba(255, 255, 255, 0.95);

    a, button {

      display: block;
      width: 100%;
      color: $c-dark;
      font-weight: 400;
      font-size: small;
      border-radius: 0;
      text-align: left;
      padding: $scale1 $scale;
      border: 0px;
      border-bottom: 1px solid $c-dark;
      background-color: transparent;

      &:hover {

        opacity: 1;
        background-color: transparent;

      }

      &:last-child {

        border-bottom: 0 !important;

      }
    }


  }
}

.left {

  left: 0;
  text-align: left;

  nav:after {

    right: auto;
    left: 0.5em;

  }
}

.right {

  right: 0;
  text-align: right;

  nav:after {

    right: 0.5em;
    left: auto;

  }
}

.dark {

  nav {

    background: $au-purple-90;
    /*background: rgba(0, 0, 0, 0.8);*/

    a, button {

      color: lighten(white, 12%);
      border-bottom: 1px solid lighten($au-purple, 10%);

    }

    &:after {

      border-bottom-color: $au-purple;

    }
  }
}

.icon {

  position: relative;
  top: 0.1em;
  margin-right: $scale-3;

}
svg {vertical-align: top !important;}
