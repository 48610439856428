@import 'components/view/scss/global.scss';

.blankslate {

  width: 100%;
  text-align: center;

  img {

    max-width: 35%;

  }

  h2 {

    position: relative;
    margin: 0 0 $scale2;

    &:after {

      content: "";
      position: absolute;
      bottom: -0.5em;
      left: 50%;
      width: $scale4;
      height: 1px;
      background-color: darken($c-border, 5%);
      transform: translateX(-50%);
      -ms-transform: translateX(-50%);

    }
  }

  a {

    color: $c-primary;

  }

}
