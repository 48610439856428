@import 'components/view/scss/global.scss';

.image {

  max-width: 100%;
  margin: $scale;
}

.right {
  float: right;
}

.left {
  float: left;
}

.center {
  justify-self: center;
}