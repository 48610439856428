@import 'components/view/scss/global.scss';

.row {

  padding: $scale3 0 $scale5 0;
  text-align: left;
  background-color: white;

  header {

    position: relative;
    width: 80%;
    display: inline-block;

    h1 {

      color: $au-purple;
      margin-bottom: $scale3;

    }

    h2 {

      margin-top: 0;

    }
  }
  h1 {

    color: $au-purple;
    margin-bottom: $scale;

  }

  h2 {

    font-size: medium;
    color: $au-purple;
    margin-bottom: $scale;
  }
}

.brand, .dark, .transparent {

  header {

    margin-bottom: $scale;

    &:after {

      display: none;

    }
  }
}

.brand, .dark {

  header {

    h1, h2, p {

      color: white;

    }
  }

  blockquote, p {

    color: white;

  }
}

.transparent {

  background-color: transparent;

}

.tint {

  background-color: $c-bg;

}

.dark {

  background-color: $c-dark;

}

.brand {

  background-color: $c-purple;

}

.center {
  text-align: center;
}

.left {

  text-align: left;

  header {

    text-align: left;

    &:after {

      left: 0;
      transform: none;

    }
  }
}

.right {

  text-align: right;

  header {

    text-align: right;

    &:after {

      right: 0;
      transform: none;

    }
  }
}

.rightspot {

  background-image: linear-gradient(45deg, $c-bg, $c-bg, $c-bg, #d6edfd);
  
}

.leftspot {

  background-image: linear-gradient(315deg, $c-bg, $c-bg, $c-bg, #d6edfd);
  
}


