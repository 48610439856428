.restrict-width {

  max-width: $max-width;
  margin: 0 auto;

}



.show {

  display: block;

}

.hide {

  display: none;

}
