@import 'components/view/scss/global.scss';

.onboarding {

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;

}
