@import 'components/view/scss/global.scss';

.form {

  > header {

    padding: $scale 0 0 0 !important;
    
    h2 {

      margin-bottom: $scale-4;

    }

    p {

      font-size: $scale1;

    }
  }

  > a {

    display: block;
    margin-bottom: $scale;

  }

  button, .btnLoader {
    margin-right: $scale;
    width: $scale10;
  }
}

.loading {

  opacity: 0.5;

}
