@import 'components/view/scss/global.scss';

.search {

  position: relative;

}

.btn {

  display: block;
  position: absolute;
  right: 1.2em;
  top: 50%;
  z-index: 2;
  width: 1.5em !important;
  height: 1.5em !important;
  cursor: pointer;
  opacity: 0.5;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  background-size: contain;
  background-color: transparent;
  background-position: center center;
  background-image: url("./icons/ico-search.svg");

  &:hover {

    opacity: 1;
    transition: all 0.2s ease-in-out;

  }
}
