@import 'components/view/scss/global.scss';

.subnav {
  width: 100%;
  overflow: hidden;
  padding: $scale/3 0 0 $scale;

  a {
   display: inline-block;
    padding-bottom: $scale/3;
    margin-right: $scale2;
    text-decoration: none;
  }
  &.sticky {
    position: fixed;
    top:var(--header-height);
    
  }
  &.guest {
    background-color: white;
    border-bottom: 1px solid $c-border;
    text-align: center;
    z-index: 7;
    a {color: $au-purple;
    }
  }
}
