/* colours */
$c-bg: #ffffff;
$c-text: #000000;
$c-primary: #73B0F4;
$c-dark: #56586d;
$c-text-light: lighten($c-text, 20%);
$c-border: lighten($c-dark, 25%);
$c-border-dark: $c-dark;

// social media
$c-facebook: #3b5998;
$c-google: #ffffff;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #cc6666;
$c-green: #009900;
$c-blue: #73B0F4;
$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-darkpurple: #556CB6;

/* amp-up.io */
$au-font: #573C81;
$au-purple: #573C81;
$au-purple-90: rgba(87, 60, 129, 0.9); //#573C81
$au-purple-80: rgba(87, 60, 129, 0.8); //#573C81