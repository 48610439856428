@import 'components/view/scss/global.scss';


.project {
  text-align: left;
  margin-right: $scale;
  align-self: center;
}

.user {
  text-align: right;
  margin-right: $scale;
  align-self: center;
}

a.navItem {
  display: inline-block;
  padding-bottom: $scale/3;
  margin-right: $scale2;
  text-decoration: none;
  color: $au-purple;
     
 
}