@import 'components/view/scss/global';

.paginate {

  display: inline-block;
  margin-bottom: $scale;

  button {

    float: left;
    margin-top: 0.1em;

  }

  ul {

    float: left;

    li {

      float: left;
      cursor: pointer;
      padding: 0 0.2em;

    }
  }
}

.counter {

  float: left;
  margin: 0 0.5em;
  font-size: 0.9em;

}