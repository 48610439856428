@import 'components/view/scss/global.scss';

.tabs {

  overflow: visible;
  position: relative;

  button {
    
    position: relative;
    color: $c-text;
    padding: $scale $scale5 $scale;
    color: lighten($c-text, 15%);
    outline: none;
    color: white;
    font-size: 0.85em;
    background-color: $c-border-dark;
    transition: all 0.2s ease-in-out;

    &:hover {

      transition: all 0.2s ease-in-out;

    }
    &:first-of-type {
      &:after {
        left: 0px !important;
      }
    }
  }

  @media (max-width: $mobile){

    border-bottom: 1px solid $c-border;

    button {

      width: 100%;
      color: $c-text;
      padding: $scale $scale2;
      background-color: white;
      border-bottom: 1px solid $c-border;

      &:last-child {

        border-bottom: none;

      }

      &:before {

        display: none;

      }
    }
  }
}

button.active {

  color: $c-text;
  background-color: white;
  z-index: 3;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  &:before {

    border-radius: 4px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $c-border-dark;
  }

  &:after {
    content: "";
    position: absolute;
    bottom:-10px;
    left: -10px;
    right: -10px;
    height: 10px;
    background-color: white;
  }


  @media (max-width: $mobile){

    color: white;
    border-bottom: none;
    background-color: $c-blue;

  }
}
