@import 'components/view/scss/global.scss';

.grid {

  overflow: hidden;
  
  > * {
    display: inline-block;
    margin: 5px;
    width: 100%;

  }
}

.two {

  > * {

    @media (min-width: 600px){
      display: inline-block;
      width: 48%;

      &:nth-of-type(2n+1){

        margin-right: 1%;

      }
    }
  }
}

.three {

  > * {

    // switch to three column layout
    @media (min-width: 850px){
      display: inline-block;

      width: 31%;

      // reset from two-col
      &:nth-of-type(2n+1){

        margin-right: 0;

      }

      &:nth-of-type(3n+1),
      &:nth-of-type(3n+2){

        margin-right: 1%;

      }
    }
  }
}

.four {

  > * {

    // switch to four column layout
    @media(min-width: 1100px){

      width: 23%;

      // reset from 3 col
      &:nth-of-type(3n+1),
      &:nth-of-type(3n+2){

        margin-right: 0;

      }

      &:nth-of-type(4n+1),
      &:nth-of-type(4n+2),
      &:nth-of-type(4n+3){

        margin-right: 1.33%;

      }
    }
  }
}

.five {

  > * {

    // switch to five column layout
    @media(min-width: 1400px){

      width: 18.9%;

      // reset
      &:nth-of-type(4n+1),
      &:nth-of-type(4n+2),
      &:nth-of-type(4n+3){

        margin-right: 0%;

      }

      &:nth-of-type(5n+1),
      &:nth-of-type(5n+2),
      &:nth-of-type(5n+3),
      &:nth-of-type(5n+4){

        margin-right: 1%;

      }
    }
  }
}

.six {

  > * {

    @media (min-width: 1900px){

      width: 15.2%;

      // reset
      &:nth-of-type(5n+1),
      &:nth-of-type(5n+2),
      &:nth-of-type(5n+3),
      &:nth-of-type(5n+4){

        margin-right: 0%;

      }

      &:nth-of-type(6n+1),
      &:nth-of-type(6n+2),
      &:nth-of-type(6n+3),
      &:nth-of-type(6n+4),
      &:nth-of-type(6n+5){

        margin-right: 1%;

      }
    }
  }
}
