@import 'components/view/scss/global.scss';




nav.navbar {

  

  width: 100%;
  height: 100%;
  z-index: 9;
  overflow: hidden;

  &.open {

    a span.label,
    div span.label {

      opacity: 1;

    }
  }
  .navItem {
    margin-right: $scale;
    color: white;
    text-decoration: none;
    align-self: center;
    justify-self: stretch;
  }
  
  .nav-links {

    padding: 0;
    white-space: nowrap;

    a, div {

      position: relative;
      display: block;
      text-decoration: none;
      color: white;
      padding: 0;
      cursor: pointer;
      width: 100% !important;
      height: $scale5;
      padding: $scale $scale-3;
      font-size: 1em;
      line-height: 1;

      .icon {

        position: absolute;
        top: 50%;
        left: $scale-1;
        transform: translateY(-50%);

      }

      .label {

        opacity: 0;
        pointer-events: none;
        margin-left: $scale4;

      }

      &.active {

        color: white;
        border-radius: $radius;
        background-color: $c-blue;
        background: lighten($c-dark, 5%);

      }
    }
  }
}
