@import 'components/view/scss/global.scss';
@import './normalize.scss';
@import './typography.scss';

:root {
  --landscape-width: 25vw; // % OR vw for responsive window resizing!
  --portrait-width: 60vw;
  --sidebar-width: 11rem;
  --header-height: 3rem;
  --secs: 0.4s;
}

.ampup {
  text-decoration: none;
  font-size: $scale1;
  margin-top: $scale-3;
  a {text-decoration: inherit;color:inherit}
}

.menu {
  text-decoration: none;
  font-size: $scale;
  margin-top: $scale;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  overflow: hidden; // Disable scrolling on body
}

#layout {
  display: flex; // Flexbox definition
  overflow: hidden; // Disable scrolling on layout

  // Style in common for sidebars and main area:
  .header {
    position: relative;
    height: var(--header-height);
    .title { position: absolute; }
    background: $c-dark;
    color: white;
  }
  .sidebar {
    background-color: lighten($c-dark, 10%);
  }
  &.guest {
    .header {
      color: $au-purple !important;
      background: white !important;
      @media (orientation: landscape) {
        .title {
          &.left-open { left: calc(var(--sidebar-width) + $scale); }
        }
      }
    }
    #left {
      width: 0;
    }
  }
  &.lti {
    display:inline;
    .header {
      z-index: 999;
      display: flex;
      text-align: left;
      padding: 0 $scale;
      -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
      -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
      box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
    }
  }

  #content {
    display: block;
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    padding: 1rem;
    width: 100%;
    &.marginless {
      height: 100vh;
      padding:0rem;
      >.testPlayer {
        height: 100vh;
      }
    }
  }
  .navbar {
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
  }
  
}


// Main area specific style:
#main { background: var(--bg-main); flex-grow: 1;
  .header { 
    display: flex;
    text-align: left;
    padding: 0 $scale;
    -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
    .title {left: calc(var(--header-height) + $scale); }
    @media (orientation: landscape) {
      .title { transition: left var(--secs), right var(--secs);
        &.left-closed { left: calc(var(--header-height) + $scale); }
        &.left-open { left: $scale; }
      }
    }
    @media (orientation: portrait) {
      .title { transition: left var(--secs), right var(--secs);
        &.left-closed { left: calc(var(--header-height) + $scale); }
        &.left-open { left: calc(var(--sidebar-width) + $scale); }
      }
    }
    .spacer {
      flex:auto;
    }
  }
}

// Style in common for both sidebars:
#left { 
  transition: width var(--secs); 
  width: 0;
  @media (orientation: landscape) {
    &.open { width: var(--sidebar-width); }
  }
  .icon { position: fixed; z-index: 10;
    width: calc(var(--header-height)); height: calc(var(--header-height));
    line-height: var(--header-height); font-size: var(--header-height);
    padding: calc(var(--header-height) * 0.1);
    text-align: center; user-select: none; cursor: pointer;
  }
  .sidebar { 
    transition: width var(--secs), visibility var(--secs); // <-- Portrait AND landscape!
    visibility: hidden;
    width: 0px;
    .title {opacity: 0; white-space: nowrap; transition: opacity var(--secs);}

    &.open { 
      visibility:visible; 
      width: var(--sidebar-width);
      .title {opacity: 1}
    }

  }
  z-index: 5;
  .header {  
    .title {left: var(--header-height);}
  }
}

